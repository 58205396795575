@import "variables.scss";
@include font-face("Rouble", "/fonts/ALSRubl_gdi");

.rouble {
	font-family: 'Rouble';
}

a:focus {
	outline: none;
}


/*  dropdown menu separate link and arrow  */
.navbar-toggle{
	margin-top:18px;
	margin-bottom:18px;
}
.navbar-header{
	height:70px;
	position: relative;
	.navbar-toggle{
		@media(max-width:767px){
			position: absolute;
			right: 10px;
			background-color:#fff;
		}
	}
}
.navbar-collapse.collapsing,
.navbar-collapse.collapse.in{
	max-height: 440px;
	float: right;
	width: 80%;
	max-width: 300px;
	position:absolute;
	right:20px;
	background-color:#fff;
	background-color: #FFF;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176);
	ul{
		.dropdown{
			a{
				display:block;
				margin-right:40px;
			}
			span{
				position:absolute;
				right:0px;
			}
		}
		.dropdown-menu{
			li{
				width:100%;
				a{
					white-space: normal;
				}
			}
		}
	}
}
.navbar{
	background-color:#fff;
	.container{
		padding-right:35px;
	}
	.navbar-brand{
		padding:15px;
	}
	.nav > li.dropdown.separated > a{
		display:inline-block;
		padding-right:5px;
	}
	.nav > li{
		&.open,
		&:hover{
			background-color:#f7f7f7;
			span{
				background-color:#f7f7f7!important;
			}
		}
		a{
			line-height:25px;
			
		}
		&>a{
			line-height:40px;
			padding:15px;
		}
		&.separated>span{
			line-height:38px;
			display:inline-block;
			padding:15px 5px;
			color:#777;
			border-left:1px solid #fff;
			background-color:#fff;
			&:hover{
				color:#333;
				cursor:pointer;
				border-left:1px solid #fff;
				background-color:#f7f7f7;
			}
		}
		&.basket
		{
			a{
				padding:0px 10px!important;
				margin:0px 10px;
				span{
					padding:5px 0;
					font-size:26px;
					line-height: 60px;
					-moz-transform: scale(-1, 1);
					-webkit-transform: scale(-1, 1);
					-o-transform: scale(-1, 1);
					transform: scale(-1, 1);
					filter: FlipH;
					-ms-filter: "FlipH";
				}
				.basketAmountAll{
					position:absolute;
					color:#fff;
					top:13px;
					right:20px;
					text-shadow: 0 0 1px #000;
				}
			}
		}
		&.basket-xs
		{
			a{
				margin-right:0px!important;
			}
			span{
				right:20px!important;
				padding:5px 0;
				line-height: 60px;
				-moz-transform: scale(-1, 1);
				-webkit-transform: scale(-1, 1);
				-o-transform: scale(-1, 1);
				transform: scale(-1, 1);
				filter: FlipH;
				-ms-filter: "FlipH";
			}
		}
	}
	.navbar-nav > .open > a,
	.navbar-nav > .open > a:hover,
	.navbar-nav > .open > a:focus,
	.navbar-nav > .open > span{
		background-color: #f7f7f7;
		color: #555;
	}
}


a.basket>span:first-child{
	padding-right:5px;
}



body>.container{
	margin-top:20px;
}

.flex{
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	//justify-content: space-between;
	justify-content: space-around;
	margin: 0 -10px;
	@media(max-width:767px){
		justify-content: space-around;
	}
	&>div{
		width:265px;
		@media(max-width:1199px){
			width:215px;
		}
		@media(max-width:991px){
			width:245px;
		}
		@media(max-width:767px){
			width:200px;
		}
		img{
			//width:100%;
		}
	}
}

.thumbnail.architector{
	height: 300px;
	padding:10px;
	overflow:hidden;
	img{
		max-height:136px;
	}
	h2{
		margin-top: 0px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}
	span.caption{
		line-height: 98%;
	}
}
.thumbnail.vara{
	border-width: 2px;
	overflow: hidden;
	text-align: center;
	background: #FFF none repeat scroll 0% 0%;
	span.caption{
		padding:0;
	}
	p{
		font-size: 16px;
	}
	em{
		font-size: 12px;
		display: block;
		height: 30px;
		vertical-align: middle;
		line-height: 95%;
		text-decoration:none;
	}
	h2 {
		margin: 0px 10px;
		padding-bottom: 0px;
		font-size: 16px;
		font-weight: bold;
		height: 52px;
		vertical-align: middle;
	}
	img{
		max-height:155px;
		min-height:135px;
	}
}
.thumbnail.project{
	min-height:220px;
	span{
		display:block;
		height:150px;
		background-size:cover;
		background-repeat:no-repeat;
		background-position: center;
	}
	h2 {
		height: 54px;
		overflow: hidden;
		margin: 10px 0px 5px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}
}



aside{
	padding-left:0px!important;
	//padding-right:0px!important;
	.list-group{
		background:#8D8988;
		padding:10px 0 ;
		border-radius:4px;
		h2{
			margin-top:10px;
			word-wrap: break-word;
		}
		h2:first-child{
			margin-top:-10px;
		}
	}
	a.list-group-item{
		padding:5px 15px;
		small{
			color:#bbb;
		}
	}
	a.list-group-item.second-level{
		padding:5px 15px 5px 40px;
		font-size:12px;
	}
}


/*  market  */
section.vitrina{
	margin: 0px 0px 20px;
	padding: 10px 0px;
	background: #FFF none repeat scroll 0% 0%;
	border-bottom: 2px solid #CCC;
	border-top: 2px solid #CCC;
	img{
		margin:50px 0;
		width:100%;
	}
}

/* Sticky footer styles
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
}
body {
	/* Margin bottom by footer height */
	margin-bottom: 100px;
	padding-top: 71px;;
}
.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	/* Set the fixed height of the footer here */
	height: 60px;
	background-color: #2B2622;
	color: #eee;
	text-align:center;
}
.footer a{
	color:#eee;
}
.footer a:hover{
	color:#fff;
}
.footer>div{
	margin:20px 0 0;
}
@media(max-width:993px){
	.footer>div{
		margin:10px 0 0;
	}
}
@media(max-width:555px){
	body {
	  margin-bottom: 100px;
	}
	.footer{
		height: 80px;
	}
}
@media(max-width:363px){
	body {
	  margin-bottom: 120px;
	}
	.footer{
		height: 100px;
	}
}


input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }
textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }


#breadcrumbs{
	padding: 8px 15px;
	margin: 0 0 20px;
	list-style: outside none none;
	background-color: #F5F5F5;
	border-radius: 4px;
	a:after{
		content:' / ';
	}
	a.active{
		color:#888;
	}
	a.active:after{
		content:'';
	}
	a:hover{
		text-decoration:none;
	}
	
}


/* page */
.page-header{
	margin:20px 0 20px;
}
h1.page-header{
	font-size: 22px;
	font-weight: bold;
}

.date{
	font-style: italic;
}

h2.list-group-item{
	margin:0;
	font-size:26px;
}
p.list-group-item-text{
	font-style:italic;
	color:#ddd;
}
a.list-group-item{
	padding:5px 10px;
}
a.thumbnail:hover,
a.thumbnail:active,
a.thumbnail:focus
{
	border-color:#aaa;
}


/*  pagination */
#nextPageContent{
	span{
		width:80%;
		margin:auto;
	}

}


.share42init{
	clear:both;
	padding:20px 0;
}
